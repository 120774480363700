//--------------------------------------------------
//Classroom Page for Teachers
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { userActions } from '../../Actions';

const ApprovalList = ({ key }) => {

    const params = new URLSearchParams(new URL(window.location.href).search);
    const classroom_id = params.get("classroom");
    const token = localStorage.getItem("token");

    const [content, setContent] = useState(null);
    const [questElements, setQuestElements] = useState([]); //holds the html content for each quest and phase
    const [openQuests, setOpenQuests] = useState([]); //holds whether the quest dropdowns are open or not
    const [openPhases, setOpenPhases] = useState([]); //holds whether the phase dropdowns are open or not


    useEffect(() =>
        GetApprovals(),
        []);

    function GetApprovals() {
        userActions.GetApprovals(classroom_id, params.get("student"), token, (data) => {
            //console.log(data);
            setContent(data);
        })
    }

    function approveContent(approval_id, student_id) {
        userActions.ApproveContent(approval_id, classroom_id, student_id, token, (data) => {
            GetApprovals();
        })
    }

    function denyContent(approval_id, student_id) {
        userActions.DenyContent(approval_id, classroom_id, student_id, token, (data) => {
            GetApprovals();
        })
    }

    // Function to toggle quest dropdown
    const toggleQuest = (questNumber) => {
        if (openQuests.includes(questNumber)) {
            setOpenQuests(openQuests.filter((quest) => quest !== questNumber));
        } else {
            setOpenQuests([...openQuests, questNumber]);
        }
    };

    // Function to toggle phase dropdown
    const togglePhase = (phaseNumber) => {
        if (openPhases.includes(phaseNumber)) {
            setOpenPhases(openPhases.filter((phase) => phase !== phaseNumber));
        } else {
            setOpenPhases([...openPhases, phaseNumber]);
        }
    };

    useEffect(() => {

        if (content) {
            if (content.responses.length > 0) {
                // Create an array to store the unique quests
                const uniqueQuests = [...new Set(content.responses.map(response => response.quest))];

                // Map over each unique quest
                const questsWithPhases = uniqueQuests.map(questNumber => {
                    // Filter responses that match the current quest number
                    const filteredPhases = content.responses
                        .filter(response => response.quest === questNumber)
                        .map((response) => (
                            <div key={response.id} className="Phase">
                                <div className='dropdown'>
                                    <h2 onClick={() => togglePhase(response.phase)}>Phase {response.phase}</h2>
                                    {openPhases.includes(response.phase) ? <span class="material-symbols-outlined">keyboard_arrow_down</span> : <span class="material-symbols-outlined">keyboard_arrow_right</span>}
                                </div>
                                {openPhases.includes(response.phase) &&
                                    <div className="phaseContent">
                                        {/* Check if content is a string or an object */}
                                        {typeof response.content === 'string' ? (
                                            <p>{response.content}</p>
                                        ) : (
                                            Object.entries(response.content).map(([key, value], idx) => (
                                                <div key={idx}>
                                                    <h3>{key}: </h3>
                                                    <p>{value}</p>
                                                </div>

                                            ))
                                        )}
                                        {/* if there is an image, add it */}
                                        {response.image && (
                                            <img src={userActions.GetFile(response.image)} alt="Exhibit" />
                                        )}
                                    </div>
                                }

                                {/* Approval and deny buttons at the end of each phase */}
                                <div>
                                    <button
                                        onClick={() => approveContent(response.id, response.user_id)}
                                        className='approvalButtonApprove'
                                    >
                                        Approve
                                    </button>
                                    <button
                                        onClick={() => denyContent(response.id, response.user_id)}
                                        className='approvalButtonDeny'
                                    >
                                        Deny
                                    </button>
                                </div>
                            </div>
                        ));

                    // Return the HTML structure for each quest
                    return (
                        <div key={questNumber} className="Quest">
                            <div className='dropdown'>
                                <h1 onClick={() => toggleQuest(questNumber)}>
                                    Quest {questNumber}
                                </h1>
                                {openQuests.includes(questNumber) ? <span class="material-symbols-outlined">keyboard_arrow_down</span> : <span class="material-symbols-outlined">keyboard_arrow_right</span>}
                            </div>
                            {/* Conditionally render phases if quest is open */}
                            {openQuests.includes(questNumber) && (
                                <div className="phasesContainer">
                                    {filteredPhases}
                                </div>
                            )}
                        </div>
                    );
                });

                // Set the questElements state with the generated HTML structure
                setQuestElements(questsWithPhases);
            }
        }
        else {
            const noPhases = (
                <div className='studentRow'>
                    <span className='bold font-lg grid-col-12'>This {params.get("student") ? 'student' : 'class'} has not yet completed any phases!</span>
                </div>
            )
            setQuestElements(noPhases)
        }
    }, [content, openQuests, openPhases]);

    return (
        <Fragment>
            <head>
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
            </head>
            <div className={'studentView ApprovalView'} key={key}>
                <div className='grid-col-2 headerRow'>
                    <h1 className='ApprovalHeader'>Approval Requests: <span className='text-black'>({content?.responses?.length})</span></h1>
                    <h2 className='ApprovalHeader'>
                        {content?.responses?.length > 0
                            ? `${content.responses[0].username} : ID ${content.responses[0].student_id}`
                            : ''}
                    </h2>
                </div>
                <div className='studentContainer'>
                    {questElements}
                </div>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(ApprovalList);
export { connection as ApprovalList };