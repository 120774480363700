//--------------------------------------------------
//Defines user requests to interact with the server and sends to redux
//--------------------------------------------------

import { alertConstants, sessionConstants, crudConstants } from "../Redux/constants";
import env from "react-dotenv";
import { alertActions } from "../Actions";

const API = env?.API || "https://idagem.org/api/"

//const API = "http://localhost:5000/api/"

//"http://localhost:5000/api/"
//"https://idagem.org/api/"

console.log(API);

//"https://lunarpenguin.net/idagem-server/api/" //s//URL of server if in production

export const userActions = {
    //General
    reroute,

    //User 
    LoginTeacher,
    RegisterTeacher,
    LoginStudent,
    RegisterStudent,
    RegisterStudentAsTeacher,
    SendResetEmail,
    CheckResetCode,
    ResetPassword,
    DeleteAccount,
    Logout,
    EditAccount,
    VerifyEmail,
    SendVerificationEmail,

    //Actions
    DefaultAction,
    FindUserById,
    Upload,
    Loading,
    GetFile,
    GetUserData,

    //Requests
    GetSchoolList,
    RemoveStudent,
    GetClassroomName,
    GetClassroomContent,
    CreateClassroom,
    DeleteClassroom,
    GetClassroomList,
    RefreshClassroomCode,

    GetActiveQuests,
    GetQuests,
    ToggleLockQuest,
    GetQuestContent,

    CreateQuest,
    UpdateQuest,
    DeleteQuest,
    UpdateTravelogue,

    GetTravelogue,
    GetStudentTravelogue,
    GetStudentExhibits,

    GetApprovals,
    ApproveContent,
    DenyContent,
}


function DefaultAction(token) {
    return handleApiRequest("DefaultPath", "POST", { token }, (data, dispatch) => {
        const { message, user } = data;
        if (message && user) {
            dispatch(redux(sessionConstants.DefaultConstSUCCESS, user))
            dispatch(alertActions.Success(message));
        }
    })
}

function VerifyEmail(token, verificationToken) {
    return handleApiRequest("VerifyEmail", "PUT", { token, verificationToken }, (data, dispatch) => {
        const { message } = data;
        if (message) {
            dispatch(alertActions.Success(message))
            dispatch(redux(sessionConstants.Logout, "Logout"))
            setTimeout(() => reroute("Login"), 750);
        }
    })
}

function SendVerificationEmail(token) {
    return handleApiRequest("SendVerificationEmail", "POST", { token }, (data, dispatch) => {
        const { message } = data;
        if (message) dispatch(alertActions.Success(message))
    })
}

function EditAccount(token, first_name, last_name, icon, school, email, password, onComplete) {

    const form = new FormData();
    form.append('first_name', first_name);
    form.append('last_name', last_name);
    form.append('icon', icon);
    form.append('school', school);
    form.append('email',  email);
    form.append('password', password);

    return handleApiRequest("EditAccount", "PUT", form, (data, dispatch) => {
        const { message, changedEmail } = data;
        if (message) dispatch(alertActions.Success(message));

        if (changedEmail) {
            Logout()
        } else {
            onComplete()
        }
    }, {"Authorization": token}, true )
}

function FindUserById(token, id) {
    return handleApiRequest("FindUserById", "POST", { token, id }, (data, dispatch) => {
        const { message, userById } = data;
        if (userById[0]) {
            dispatch(redux(sessionConstants.FindUserSuccess, userById[0]))
        }
    })
}

function DeleteAccount(token, id) {
    return handleApiRequest("DeleteAccount", "DELETE", { token, id }, (data, dispatch) => {
        const { message } = data;
        if (message) {
            dispatch(alertActions.Success(message));
            dispatch(redux(sessionConstants.Logout, "Logout"))
            reroute("Login")
        }
    })
}

function LoginTeacher({ email, password, useCaptcha, captchaToken }) {
    const requestData = { email, password, useCaptcha, 'status': 'teacher', };
    if (useCaptcha) { requestData.captchaToken = captchaToken }
    return handleApiRequest("Login/Teacher", "POST", requestData, (data, dispatch) => {
        const { message, user, enableCaptcha } = data;
        if (message && user) {
            dispatch(redux(sessionConstants.LoginSuccess, user))
            dispatch(alertActions.Success(message));
            setTimeout(() => reroute("dashboard"), 750);
        }
        if (enableCaptcha != null) {
            dispatch(redux(sessionConstants.UseCaptcha, enableCaptcha))
        }
    })
}

function LoginStudent({ username, student_id, classroom_code, useCaptcha, captchaToken }) {
    const requestData = { username, student_id, classroom_code, useCaptcha, 'status': 'student', 'type': 'login' };
    if (useCaptcha) { requestData.captchaToken = captchaToken }
    return handleApiRequest("Login/Student", "POST", requestData, (data, dispatch) => {
        const { message, user, enableCaptcha } = data;
        if (message && user) {
            dispatch(redux(sessionConstants.LoginSuccess, user))
            dispatch(alertActions.Success(message));
            setTimeout(() => reroute("game/"), 750);
        }
        if (enableCaptcha != null) {
            dispatch(redux(sessionConstants.UseCaptcha, enableCaptcha))
        }
    })
}

function RegisterTeacher({ first_name, last_name, email, password, school }) {
    return handleApiRequest("Register/Teacher", "POST", { first_name, last_name, email, password, school, 'status': 'teacher', }, (data, dispatch) => {
        const { message, user, enableCaptcha } = data;
        if (message) {
            dispatch(redux(sessionConstants.LoginSuccess, user))
            dispatch(alertActions.Success(message));
            setTimeout(() => reroute("dashboard"), 750);
        }
    })
}

function RegisterStudent({ student_id, classroom_code }) {
    return handleApiRequest("Register/Student", "POST", { student_id, classroom_code, 'status': 'student', 'type': 'register' }, (data, dispatch) => {
        const { message, user, enableCaptcha } = data;
        if (message) {
            dispatch(redux(sessionConstants.LoginSuccess, user))
            dispatch(alertActions.Success(message));
            setTimeout(() => reroute("game/"), 750);
        }
    })
}

function RegisterStudentAsTeacher({ first_name, last_name, student_id, classroom_code, linked_to_teacher }) {
    return handleApiRequest("Register/Student", "POST", { student_id, classroom_code, 'status': 'student', }, (data, dispatch) => {
        const { message, user, enableCaptcha } = data;
        if (message) {
            dispatch(redux(sessionConstants.LoginSuccess, user))
            dispatch(alertActions.Success(message));
            setTimeout(() => reroute("game/"), 750);
        }
    })
}

function SendResetEmail({ email }) {
    return handleApiRequest("SendResetEmail", "POST", { email }, (data, dispatch) => {
        const { message } = data;
        if (message) {
            dispatch(alertActions.Success(message));
            dispatch(redux(sessionConstants.ResetEmailSuccess, ""))
        }
    })
}

function CheckResetCode({ code, email, captchaToken }) {
    return handleApiRequest("CheckResetCode", "POST", { code, email, captchaToken }, (data, dispatch) => {
        const { message, accessToken } = data;
        if (message) {
            dispatch(alertActions.Success(message));
            if (data.accessToken) dispatch(redux(sessionConstants.CheckResetCodeSuccess, accessToken))
        }
    })
}

function ResetPassword({ accessToken, password }) {
    return handleApiRequest("ResetPassword", "PUT", { accessToken, password }, (data, dispatch) => {
        const { message } = data;
        if (message) {
            dispatch(alertActions.Success(message));
            setTimeout(() => reroute("settings"), 750);
        }
    })
}

function handleApiRequest(endpoint, method, body, onApiSuccess, headers, stringifyBody) {
    return (dispatch) => {
        dispatch(redux(sessionConstants.REQUEST, endpoint));
        dispatch(alertActions.Clear());
        fetch(API + endpoint, {
            method: method,
            headers: !headers ? { "Content-Type": "application/json" } : headers,
            body: body && !stringifyBody ? JSON.stringify(body) : body,
        })
            .then((res) => {
                if (res.status === 429) {
                    dispatch(alertActions.Error("Too many requests. Please try again later."));
                    throw new Error('Too many requests');
                }
                return res.json();
            })
            .then((data) => {
                const { err } = data;
                if (err) {dispatch(alertActions.Error(err)) } 
                if (typeof onApiSuccess === 'function') onApiSuccess(data, dispatch)
            })
            .catch((err) => console.log("There was an error in the request", err));
    };
}

function Upload(files, onComplete) {
    fetch(API + "Upload", {
        method: "POST",
        // headers: {"Content-Type": 'multipart/form-data'},
        body: files,
    })
        .then((res) => {
            if (res.status === 429) {
                throw new Error('Too many requests');
            }
            return res.json();
        })
        .then((data) => {
            const { err, message } = data;
            onComplete(err, message, data);
        })
        .catch((err) => console.log("There was an error in the request"));
}

function GetFile(fileName) {
    return API + 'file/' + fileName;
}

function Logout() {
    return (dispatch) => {
        dispatch(redux(sessionConstants.Logout, "Logout"))
        reroute("Login")
    }
}

function redux(type, info) {
    return { type: type, info };
}

//Value is true/false to display the loading screen
function Loading(value) {
    return (dispatch) => {
        dispatch(redux(alertConstants.Loading, value))
    }
}

function reroute(path) {
    window.location.assign(`${window.location.origin}/${path}`);
}

function GetUserData(token, onComplete) {
    fetch(API + 'user/data', {
        method: 'GET',
        headers: { "Content-Type": "application/json", "Authorization": token },
    })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            onComplete(data);
        });
}



//------------------------------
//             DHS
//------------------------------

function GetSchoolList(state, onComplete) {
    fetch(API + 'list/schools/' + state)
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            onComplete(data.data)
        });
}

function RemoveStudent(studentID, class_id, token, onComplete) {
    fetch(API + 'remove/student/' + studentID + '/classroom/' + class_id, {
        method: 'PUT',
        headers: { "Content-Type": "application/json", "Authorization": token },
    })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            onComplete();
        });
}

function GetClassroomName(classroom_code, onComplete) {
    fetch(API + "name/classroom/" + classroom_code, {
        method: 'GET',
        headers: { "Content-Type": "application/json" },
    })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            onComplete(data);
        });
}

function GetClassroomContent(classroom_id, token, onComplete, query) {
    fetch(API + 'list/classroom/' + classroom_id + '/students' + query, {
        method: 'GET',
        headers: { "Content-Type": "application/json", "Authorization": token },
        //body: JSON.stringify(body),
    })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            onComplete(data);
        });
}

function CreateClassroom(className, grade, icon, token, onComplete) {
    const form = new FormData();
    form.append('icon', icon);
    form.append('grade', grade);
    form.append('name', className);

    fetch(API + 'create/classroom', {
        method: 'PUT',
        headers: { //"Content-Type": "application/json", 
            "Authorization": token },
        body: form,
    })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            onComplete();
        });
}

function DeleteClassroom(class_id, token, onComplete) {

    fetch(API + 'delete/classroom/'+class_id, {
        method: 'PUT',
        headers: { //"Content-Type": "application/json", 
            "Authorization": token },
    })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            onComplete();
        });
}

function GetClassroomList(token, onComplete) {
    fetch(API + 'list/classrooms', {
        method: 'GET',
        headers: { "Content-Type": "application/json", "Authorization": token },
        //body: JSON.stringify(body),
    })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            onComplete(data);
        });
}

function RefreshClassroomCode(classroom_id, token, onComplete) {
    fetch(API + 'refresh/classroom', {
        method: 'PUT',
        headers: { "Content-Type": "application/json", "Authorization": token },
        body: JSON.stringify({ 'classroom_id': classroom_id }),
    })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            onComplete(data);
        });
}

function GetQuestContent(quest_id, token, onComplete) {
    fetch(API + 'quests/'+quest_id, {
        method: 'GET',
        headers: { "Authorization": token },
    })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            onComplete(data);
        });
}

function GetQuests(token, onComplete) {
    fetch(API + 'list/quests', {
        method: 'GET',
        headers: { "Authorization": token },
    })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            onComplete(data);
        });
}


function GetActiveQuests(token, class_id, onComplete) {
    fetch(API + 'list/quests/'+class_id, {
        method: 'GET',
        headers: { "Content-Type": "application/json", "Authorization": token },
    })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            onComplete(data);
        });
}

function ToggleLockQuest(token, class_id, quest_id, onComplete) {
    fetch(API + 'lock/quest/'+class_id + '/' + quest_id, {
        method: 'PUT',
        headers: { "Content-Type": "application/json", "Authorization": token },
    })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            onComplete(data);
        });
}

function CreateQuest(token, formData, onComplete) {
    fetch(API + 'add/quest', {
        method: 'POST',
        headers: { "Authorization": token },
        body: formData,
    })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            onComplete(data);
        });
}

function DeleteQuest(token, id, onComplete) {
    fetch(API + 'delete/quest/'+id, {
        method: 'PUT',
        headers: { "Authorization": token },
        //body: formData,
    })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            onComplete(data);
        });
}

function UpdateQuest(token, formData, id, onComplete) {
    fetch(API + 'update/quest/' + id, {
        method: 'POST',
        headers: { "Authorization": token },
        body: formData,
    })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            onComplete(data);
        });
}

function UpdateTravelogue(token, quest_id, formData, onComplete) {
    fetch(API + 'student/update-travelogue/' + quest_id, {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: token },
        body: JSON.stringify(formData),
    })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            onComplete(data);
        });
}

function GetTravelogue(token, quest_id, onComplete) {
    fetch(API + 'student/travelogue' + (quest_id ? '?quest_id=' + quest_id : ''), {
        method: 'GET',
        headers: { 'content-type': 'application/json', Authorization: token },
    })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            onComplete(data.answers, data.progress);
        });
}

function GetStudentTravelogue(classroom_id, student_id, token, onComplete) {
    fetch(API + 'list/travelogue/classroom/'+classroom_id+''+(student_id ? '/student/'+student_id : ''), {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', "authorization": token },
    })
    .then((res) => {
        return res.json();
    })
    .then((data) => {
        onComplete(data);
    });
}

function GetStudentExhibits(classroom_id, student_id, token, onComplete) {
    fetch(API + 'list/exhibits/classroom/'+classroom_id+''+(student_id ? '/student/'+student_id : ''), {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', "authorization": token },
    })
    .then((res) => {
        return res.json();
    })
    .then((data) => {
        onComplete(data);
    }); 
}


function GetApprovals(classroom_id, student_id, token, onComplete) {
    fetch(API + 'list/approvals/classroom/'+classroom_id+'' + (student_id ? '?student='+student_id : ''), {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', "authorization": token },
    })
    .then((res) => {
        console.log(res);
        return res.json();
    })
    .then((data) => {
        onComplete(data);
    });
}

function ApproveContent(approval_id, classroom_id, student_id, token, onComplete) {
    console.log("approve content")
    fetch(API + 'approvals/approve/id/'+approval_id+'/classroom/'+classroom_id+'/student/'+student_id, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', "authorization": token },
    })
    .then((res) => {
        return res.json();
    })
    .then((data) => {
        onComplete(data);
    });
}

function DenyContent(approval_id, classroom_id, student_id, token, onComplete) {
    console.log("deny content");
    fetch(API + 'approvals/deny/id/'+approval_id+'/classroom/'+classroom_id+'/student/'+student_id, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', "authorization": token },
    })
    .then((res) => {
        return res.json();
    })
    .then((data) => {
        onComplete(data);
    });
}