//--------------------------------------------------
//Classroom Page for Teachers
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../Actions';

const ExhibitList = ({ key }) => {

    const params = new URLSearchParams(new URL(window.location.href).search);
    const classroom_id = params.get("classroom");
    const student_id = params.get("student");
    const token = localStorage.getItem("token");
    
    const [content, setContent] = useState(null);
    const [username, setUsername] = useState("");

    useEffect(() =>
        GetExhibits(),
    []);

    function GetExhibits(){
        userActions.GetStudentExhibits(classroom_id, student_id, token, (data) => {
            setContent(data.exhibits[0].quest_exhibits)
            setUsername(data.student[0].username)
        })
    }

    function displayExhibits(exhibits){
        console.log(exhibits)
        if(!exhibits || Object.keys(exhibits).length == 0){
            return (
                <div className='studentRow'>
                    <span className='bold font-lg grid-col-12'>This {params.get("student") ? 'student' : 'class'} has not yet completed any phases!</span>
                </div>
            )
        }
        return(
            <div>
                {Object.keys(exhibits).map((questKey) => {
                    return(
                    <div>
                        <h3>Quest {questKey}</h3>

                        {ExhibitImage(exhibits[questKey][0].image)}

                        {ExhibitAnswers(exhibits[questKey][0].data)}
                    </div>
                    )
                })}
            </div>
        )
    }

    function ExhibitImage(image){
        console.log(image);
        return(
            <div>
                <img width={400} height={"auto"} src={userActions.GetFile(image)} alt={"Exhibit"}/>
            </div>
        )
    }

    function ExhibitAnswers(answers){
        const pAnswers = JSON.parse(answers)
        return(
            <div>
            {pAnswers.map((item, index) => (
                <p key={index}>{index+1}: {item}</p>
            ))}
        </div>
        )
    }

    return (
        <Fragment>
            <div className={'studentView'} key={key}>
                <div className='grid-col-2 headerRow'>
                    <h1 className='sectionHeader'>Student: <span className='text-black'>{username + " ("+student_id+")"}</span></h1>
                </div>
                <div className='studentContainer' style={{color: "black", padding: 5}}>
                    {displayExhibits(content)}
                </div>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(ExhibitList);
export { connection as ExhibitList };