//--------------------------------------------------
//Classroom Page for Teachers
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../Actions';

const Travelogue = ({ key }) => {

    const params = new URLSearchParams(new URL(window.location.href).search);
    const classroom_id = params.get("classroom");
    const student_id = params.get("student");
    const token = localStorage.getItem("token");
    
    const [content, setContent] = useState(null);
    const [username, setUsername] = useState("");

    useEffect(() =>
         GetTravelogue(),
    []);

    function GetTravelogue(){
        userActions.GetStudentTravelogue(classroom_id, student_id, token, (data) => {
            setContent(data.responses[0].travelogue);
            setUsername(data.responses[0].username)
        })
    }

    function displayTravelogue(travelogue){
        console.log(travelogue);
        if(!travelogue || Object.keys(travelogue).length == 0){
            return (
                <div className='studentRow'>
                    <span className='bold font-lg grid-col-12'>This {params.get("student") ? 'student' : 'class'} has not yet completed any phases!</span>
                </div>
            )
        }

        return(
            <div>
                {Object.keys(travelogue).map((questKey) => (
                    <div>
                        <h3>Quest {questKey}</h3>

                        {QuestDetails(travelogue[questKey].answers)}
                    </div>
                ))}
            </div>
        )
    }

    function QuestDetails(answers){
        return (
            <div>
                {Object.keys(answers).map((key) => (
                    <div>
                        {answers[key] != "" ? 
                        <div>
                            <h4>Phase {key}</h4>
                            {typeof answers[key] === "string" ? 
                            <div>
                                <p>Notes: </p>
                                <p>{answers[key]}</p>
                            </div>
                            :
                            (
                                Object.keys(answers[key]).map((subKey) => (
                                    <div>
                                        <p>{subKey}: </p>
                                        <p>{answers[key][subKey]}</p>
                                    </div>
                                ))        
                            )
                            }
                        </div>
                        :
                        <div>
                            <h4>Phase {key}</h4>
                            <p>Incomplete</p>
                        </div>
                        }
                    </div>
                ))}
            </div>
        )
    }

    return (
        <Fragment>
            <div className={'studentView'} key={key}>
                <div className='grid-col-2 headerRow'>
                    <h1 className='sectionHeader'>Student: <span className='text-black'>{username + " ("+student_id+")"}</span></h1>
                </div>

                {/* 
                
                    INLINE STYLES BELOW, TO BE DELETED LATER 
                
                */}

                <div className='studentContainer' style={{color: "black", padding: 5}}>
                    {displayTravelogue(content)}
                </div>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(Travelogue);
export { connection as Travelogue };